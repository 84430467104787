import React, { useCallback, useEffect, useState } from "react";
import AgGridComponent from "../../../../../../components/common/AgGridComponent";
import CircularProgressLoading from "../../../../../../components/loaders/CircularProgressLoading";
import NewSourceOwner from "../../../../../../Models/AddNewSourceOwner";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import GlobalConfirmationPopup from "../../../../../../components/common/GlobalConfirmationPopup";
import { axiosInstance } from "@services/axiosInstance";
import AddButton from "../../../../../../components/common/AddButton";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../services/apiQueryServices";
import {
  updateDataSOurceOwnerDetails,
  updateTableRow,
} from "../../../../../../store/DataSourceModule/DataSourceOwnerSlice";

const DataSourceOwnerDetails = () => {
  const [rowSelected, setRowSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isRegenerate, setIsRegenerate] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  const { data, refetch, isLoading, isSuccess } = useGetApiCallQuery({
    endPoint: "dl/import/source-owner/details",
    method: "POST",
    data: {
      query: {},
      options: {
        page: 1,
        limit: 10,
        pagination: false,
        options: {},
      },
    },
    headers: {},
  });
  const TableData = useSelector(
    (state) => state.dataSourceOwnerDetails.tableData
  );

  const [apiCall, res] = useApiCallMutation();
  const [apiCall1, res2] = useApiCallMutation();
  const [apiCall3, res3] = useApiCallMutation();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    dispatchData();
  }, [data, isLoading]);

  useEffect(() => {
    if (res.isSuccess) {
      successCallback(res?.data);
      setIsRegenerate(false);
      dispatch(updateTableRow(res?.data?.data));
    }
    if (res2.isSuccess) {
      successCallback(res2?.data);
      dispatch(updateTableRow(res2?.data?.data));
    }
    if (res3.isSuccess) {
      successCallback(res3?.data);
      dispatch(updateTableRow(res3?.data?.data));
    }
  }, [res, res2, res3]);

  const dispatchData = useCallback(() => {
    if (isSuccess) {
      const tableData = {
        data: [...data?.data?.data].reverse(),
        headers: data?.data?.headers,
      };
      dispatch(updateDataSOurceOwnerDetails(tableData));
    }
  }, [isSuccess, data]);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("user"))?.token.access_token
    }`,
  };

  const successCallback = (response) => {
    const status = response?.status;
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };
  const errorCallback = (error) => {
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response?.data.status.value,
      })
    );
  };

  const handleActions = (data, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(data);
        setIsEditMode(true);
        setOpen(true);
        break;
      case "ACTIVE":
        setRowSelected(data);
        if (data.isActive) {
          setIsInactive(true);
          setPopupTitle("Are you sure you want to Inactive?");
          setShowConfirmation(true);
        } else if (!data.isActive) {
          setIsActive(true);
          setPopupTitle("Are you sure you want to Active?");
          setShowConfirmation(true);
        }
        break;
      case "DELETE":
        setRowSelected(data);
        setIsDelete(true);
        setPopupTitle("Are you sure you want to Delete?");
        setShowConfirmation(true);
        break;
      case "REGENERATE":
        setRowSelected(data);
        setIsRegenerate(true);
        break;
      default: {
      }
    }
  };

  const reGenerateKey = () => {
    setShowConfirmation(true);
    setPopupTitle("Are you sure want to generate new keys?");
  };

  const openForm = () => {
    setOpen(true);
    setIsEditMode(false);
    setRowSelected({});
  };

  const handleClose = () => {
    setIsRegenerate(false);
  };

  const handleConfirm = async (remark) => {
    if (popupTitle === "Are you sure want to generate new keys?") {
      const data = {
        remark: remark,
      };

      apiCall({
        endPoint: `/dl/import/source-owner/re-generate-key/${rowSelected?.id}`, // Replace with your API endpoint
        method: "PUT",
        data: { data },
      });
    }
    if (isActive) {
      setIsActive(false);
      const data = {
        remark: remark,
        isActive: true,
      };
      apiCall1({
        endPoint: `dl/import/source-owner/active-inactive/${rowSelected?.id}`, // Replace with your API endpoint
        method: "PUT",
        data: { data },
      });
    } else if (isInactive) {
      setIsInactive(false);
      const data = {
        remark: remark,
        isActive: false,
      };
      apiCall1({
        endPoint: `dl/import/source-owner/active-inactive/${rowSelected?.id}`, // Replace with your API endpoint
        method: "PUT",
        data: { data },
      });
    } else if (isDelete) {
      setIsDelete(false);
      const data = {
        data: {
          remark: remark,
        },
      };
      apiCall3({
        endPoint: `/dl/import/source-owner/delete/${rowSelected?.id}`,
        method: "DELETE",
        data: { data },
      });
    }
    setShowConfirmation(false);
  };
  const handleCancel = (data) => {
    setShowConfirmation(false);
    setIsActive(false);
    setIsInactive(false);
    setIsDelete(false);
    setPopupTitle("");
  };
  if (isLoading) return <CircularProgressLoading />;
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 140,
          right: 16,
        }}
      >
        <AddButton onclick={openForm}> Add New Source Owner</AddButton>
      </Box>
      {open && (
        <NewSourceOwner
          defaultValues={rowSelected}
          isOpen={open}
          setOpen={setOpen}
          name={"Add Data Source Owners"}
          isEditMode={isEditMode}
        ></NewSourceOwner>
      )}
      <GlobalConfirmationPopup
        open={showConfirmation}
        title={popupTitle}
        // message="Are you sure you want to proceed?"
        onConfirm={handleConfirm}
        onClose={handleCancel}
        required={popupTitle === "Are you sure want to generate new keys?"}
      />
      {isSuccess && (
        <AgGridComponent
          rowData={TableData?.data || []}
          columnDefs={TableData.headers || []}
          actions={["EDIT", "ACTIVE", "DELETE", "REGENERATE"]}
          performAction={handleActions}
          onRowSelectionChangedHandler={(arg) => console.log(arg)}
          pagination={true}
          paginationPageSize={10}
          rowMode
        />
      )}

      <div>
        <Dialog
          fullScreen={fullScreen}
          open={isRegenerate}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth={"md"}
          fullWidth={true}
        >
          <DialogTitle id="responsive-dialog-title">
            Data count details
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <div>
                    <b>Source Owner Name</b>
                  </div>
                  <div>{rowSelected?.name}</div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    {" "}
                    <b>Api Key</b>
                  </div>
                  <div>{rowSelected?.apiKey}</div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    {" "}
                    <b>Owner Key</b>
                  </div>
                  <div>{rowSelected?.accessKey}</div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    {" "}
                    <b>Status</b>
                  </div>
                  <div>
                    {rowSelected?.isActive ? (
                      <span
                        style={{
                          color: "green",
                        }}
                      >
                        ACTIVE
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        INACTIVE
                      </span>
                    )}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div>
                    {" "}
                    <b>Remark</b>
                  </div>
                  <div>{rowSelected?.remark}</div>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={reGenerateKey} autoFocus>
              Re-Generate Key
            </Button>
            <Button variant="outlined" autoFocus onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default DataSourceOwnerDetails;
