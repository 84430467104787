import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { LinearProgressLoading } from "@components/loaders";
import { setUser } from "@store/auth/userSlice";
import { baseURL } from "@configs/envConfig";
import Header from "./Header";
import React, { useEffect } from "react";
import { axiosInstance } from "../../services/axiosInstance";

const Main = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { progressLoader } = useSelector((state) => state?.config);
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        if (JSON.parse(localStorage.getItem("user"))?.token?.access_token) {
          myFunction();
        } else {
          return <Navigate to="/login" />;
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (!document.hidden) {
      if (JSON.parse(localStorage.getItem("user"))?.token?.access_token) {
        myFunction();
      } else {
        navigate("/login");
      }
    }
  }, []);

  const myFunction = () => {
    const data = {
      clientName: "datalabs",
    };
    axiosInstance
      .post(
        `${baseURL}/secured/introspect`,
        {
          data,
        },
        {
          Authorization: JSON.parse(localStorage.getItem("user"))?.token
            ?.access_token
            ? "Bearer " +
              JSON.parse(localStorage.getItem("user"))?.token?.access_token
            : null,
          "Content-Type": "application/json",
          accept: "application/json",
        }
      )
      .then((result) => {
        if (result.data.data.active) {
        } else {
          getRefreshToken();
        }
      })
      .catch((error) => {
        getRefreshToken();
      });
  };

  const getRefreshToken = () => {
    const data = {
      clientName: "datalabs",
      refreshToken: JSON.parse(localStorage.getItem("user"))?.token
        .refresh_token,
    };
    const refreshTokenResponse = axiosInstance.post(
      `${baseURL}/core/refresh-token`,
      { data }
    );

    dispatch(setUser(refreshTokenResponse.data.data));
    const token = { ["token"]: refreshTokenResponse.data.data };
    localStorage.setItem("user", JSON.stringify(token));

    const newToken = refreshTokenResponse.data.data.access_token;
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${newToken}`;
  };

  return (
    <>
      <Header />
      {progressLoader && <LinearProgressLoading />}
      {/* set any default styles here */}
      <div>
        <Outlet />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Main;
