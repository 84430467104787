import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import GlobalInput from "@components/common/GlobalInput";
import GlobalDropdown from "@components/common/GlobalDropedown";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { axiosInstance } from "@services/axiosInstance";

import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "@configs/envConfig";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../services/apiQueryServices";
import { addRegionList } from "../../store/DataSourceModule/DataSourceOwnerSlice/regionListSlice";
import { addSourceOwnerList } from "../../store/DataSourceModule/DataSourceOwnerSlice/sourceOwnerListSlice";
import {
  addTableRow,
  updateTableRow,
} from "../../store/DataSourceModule/DataSourceDetailsSlice";

const AddNewSource = (props) => {
  const { setOpen, isOpen, defaultValues, loadData, isEditMode } = props;
  const [defaultValuesSchema, setDefaultValuesSchema] = useState({});
  // const [locationList, setLocationList] = useState([]);
  const [databaseTableList, setDatabaseTableList] = useState([]);
  // const [sourceOwner, setSourceOwner] = useState([]);
  const dispatch = useDispatch();

  const locationList = useSelector((state) => state.regionList?.data);
  const sourceOwner = useSelector((state) => state.sourceOwnerList?.data);

  const getLocationList = useGetApiCallQuery({
    endPoint: "/dl/region-info/list-items",
    method: "GET",
  });
  const getSourceOwnerList = useGetApiCallQuery({
    endPoint: "dl/import/source-owner/list-items",
    method: "GET",
  });

  useEffect(() => {
    getLocationList.refetch();

    if (getLocationList.isSuccess) {
      dispatch(addRegionList(getLocationList.data.data));
    }
  }, [getLocationList.isLoading]);
  useEffect(() => {
    getSourceOwnerList.refetch();
  }, [getSourceOwnerList.isLoading]);
  useEffect(() => {
    if (getSourceOwnerList?.data?.data.length) {
      const sourceList = [...getSourceOwnerList.data.data].reverse();
      dispatch(addSourceOwnerList(sourceList));
    }
  }, [getSourceOwnerList.data]);

  const [apiCall, res] = useApiCallMutation();
  const [apiCall1, res1] = useApiCallMutation();

  useEffect(() => {
    if (res.isSuccess && res.data !== null) {
      let finalData = { ...res?.data?.data };
      finalData.ownerName = sourceOwner.find(
        (item) => item.value == finalData.ownerId
      )?.label;
      finalData.dbId = finalData.dbName;
      finalData.locationId = finalData.location;
      finalData.locationName = locationList.find(
        (item) => item.value == finalData.location
      )?.label;
      finalData.dbName = databaseTableList.find(
        (item) => item.value == finalData.dbId
      )?.label;
      dispatch(updateTableRow(finalData));
      successCallback(res.data);
    }
    if (res1.isSuccess && res.data !== null) {
      successCallback(res1.data);
      dispatch(addTableRow(res1.data.data[0]));
    }
  }, [res, res1]);

  const trusFactorList = [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
    {
      label: "4",
      value: 4,
    },
    { label: "5", value: 5 },
    {
      label: "6",
      value: 6,
    },
    {
      label: "7",
      value: 7,
    },
    {
      label: "8",
      value: 8,
    },
    {
      label: "9",
      value: 9,
    },
    {
      label: "10",
      value: 10,
    },
  ];

  const dataSourceCategoryType = [
    { label: "Payday", value: "payday" },
    { label: "Personal", value: "personal" },
    { label: "Credit Card", value: "creditcard" },
    { label: "Auto Insurance", value: "autoinsurance" },
  ];
  const isApply = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  useEffect(() => {
    setDefaultValuesSchema(defaultValues);
    if (isEditMode) {
      SetValue();
    }
  }, [defaultValuesSchema, sourceOwner]);

  const initialValues = {
    ownerId: "",
    name: "",
    sourceId: "",
    trustFactor: "",
    location: "",
    dbName: "",
    connectToLakehouse: "",
    category: "",
    emailVerification: "",
    dupeCheck: "",
    badCheck: "",
    dupeReject: 24,
    remark: "",
  };

  const validationSchema = Yup.object().shape({
    ownerId: Yup.string().required("Source Owner Name is required"),
    name: Yup.string().required("Source Name is required"),
    sourceId: Yup.mixed()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .test(
        "is-five-digits",
        "Source ID must not begin with 0 and have min 5 or max 6 digits",
        (value) => value === null || /^[1-9]\d{4,5}$/.test(value)
      )
      .nullable(),
    trustFactor: Yup.string().required("Trust Factor is required"),
    location: Yup.string().required("Location is required"),
    dbName: Yup.string().required("Database & Table is required"),
    connectToLakehouse: Yup.string().required(
      "Connect With Lakehouce is required"
    ),
    category: Yup.string().required("Data Source Category is required"),
    emailVerification: Yup.string().required("Email Verification is required"),
    dupeCheck: Yup.string().required("Bad RecordChecks is required"),
    badCheck: Yup.string().required("Dupe RecordChecks is required"),
    dupeReject: Yup.number()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .test(
        "is-less-than-360",
        "This Field is required",
        (value) => value !== null
      )
      .required("This Field is required"),
    remark: Yup.string().max(250, "Remark must be at most 250 characters"),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    if (watch("location")) {
      const data = {
        location: watch("location"),
      };
      axiosInstance
        .post(`${baseURL}/dl/database-info/list-items`, { data })
        .then((response) => {
          if (response.data?.data?.length > 0) {
            setDatabaseTableList(response.data.data);
          } else {
            setDatabaseTableList([]);
          }
        })
        .catch(errorCallback, setDatabaseTableList([]));
    }
  }, [watch("location")]);

  const SetValue = () => {
    setValue(
      "ownerId",
      sourceOwner.some((res) => res.value === defaultValuesSchema.ownerId)
        ? defaultValuesSchema.ownerId
        : "",
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      }
    );
    setValue("name", defaultValuesSchema?.name, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("sourceId", defaultValuesSchema?.sourceId, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("trustFactor", defaultValuesSchema?.trustFactor, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("location", defaultValuesSchema?.locationId, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("dbName", defaultValuesSchema?.dbId, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue(
      "connectToLakehouse",
      defaultValuesSchema?.connectToLakehouse ? true : false,
      {
        shouldTouch: true,
        shouldDirty: true,
      }
    );
    setValue("category", defaultValuesSchema?.category, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue(
      "emailVerification",
      defaultValuesSchema?.emailVerification ? true : false,
      {
        shouldTouch: true,
        shouldDirty: true,
      }
    );
    setValue("dupeCheck", defaultValuesSchema?.dupeCheck ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("badCheck", defaultValuesSchema?.dupeCheck ? true : false, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("dupeReject", defaultValuesSchema?.dupeReject, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("remark", defaultValuesSchema?.remark, {
      shouldTouch: true,
      shouldDirty: true,
    });
  };

  const successCallback = (response) => {
    const status = response?.status;
    setOpen(false);
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };

  const errorCallback = (error) => {
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: error.response.data.status.severity
          ? error.response.data.status.severity
          : "error",
        message: error.response.data.status.value
          ? error.response.data.status.value
          : error?.message,
      })
    );
  };

  const onFormSubmit = (FormData) => {
    const data = FormData;
    data.sourceId = FormData.sourceId !== null ? Number(FormData.sourceId) : "";

    if (data.dupeCheck === "false") {
      data["dupeReject"] = 0;
    }

    if (isEditMode) {
      apiCall({
        endPoint: `dl/import/data-source/update/${defaultValuesSchema?.id}`,
        method: "PUT",
        data: { data },
      });
      // axiosInstance
      //   .put(
      //     `${baseURL}/dl/import/data-source/update/${defaultValuesSchema?.id}`,
      //     { data }
      //   )
      //   .then(successCallback)
      //   .catch(errorCallback);
    } else {
      apiCall1({
        endPoint: `/dl/import/data-source/create`,
        method: "POST",
        data: { data },
      });
      // axiosInstance
      //   .post(`${baseURL}/dl/import/data-source/create`, { data })
      //   .then(successCallback)
      //   .catch(errorCallback);
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "e" ||
      event.key === "E" ||
      event.key === "+" ||
      event.key === "-" ||
      event.key === "."
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      {/* <Button onClick={setOpen(false)}>{name}</Button> */}

      <Drawer anchor={"right"} open={isOpen} onClose={() => setOpen(false)}>
        <Box
          sx={{
            width: "70vw",
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Box marginBottom={"15px"}>
            <Typography component="h5" variant="h5" fontWeight={600}>
              {(isEditMode && "Update New Data Source") ||
                "Add New Data Source"}
            </Typography>
          </Box>
          <Divider
            sx={{
              mb: "20px",
            }}
          />
          <Box component={"form"} onSubmit={handleSubmit} pb={45}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={sourceOwner}
                  size="small"
                  control={control}
                  name="ownerId"
                  label="Source Owner"
                  error={!!errors.ownerId}
                  helperText={errors.ownerId?.message}
                  required
                  // disabled={isEditMode}
                />
              </Grid>
              <Grid item xs={4}>
                {/* <TextField
                  required
                  fullWidth
                  size="small"
                  {...register("name")}
                  id="outlined-required"
                  label="Source Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  // disabled={isEditMode}
                /> */}
                <GlobalInput
                  required
                  control={control}
                  size="small"
                  name="name"
                  label="Source Name"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  // disabled={isEditMode}
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalInput
                  control={control}
                  size="small"
                  type="number"
                  onKeyDown={handleKeyDown}
                  name="sourceId"
                  label="Source ID"
                  error={!!errors.sourceId}
                  helperText={errors.sourceId?.message}
                  // disabled={isEditMode}
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={trusFactorList}
                  control={control}
                  size="small"
                  name="trustFactor"
                  label="Trust Factor"
                  error={!!errors.trustFactor}
                  helperText={errors.trustFactor?.message}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={locationList}
                  control={control}
                  size="small"
                  name="location"
                  label="Location"
                  error={!!errors.location}
                  helperText={errors.location?.message}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={databaseTableList}
                  control={control}
                  size="small"
                  name="dbName"
                  label="Database & Table"
                  error={!!errors.dbName}
                  helperText={errors.dbName?.message}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={isApply}
                  control={control}
                  size="small"
                  name="connectToLakehouse"
                  label="Connect With Lakehouse"
                  error={!!errors.connectToLakehouse}
                  helperText={errors.connectToLakehouse?.message}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={dataSourceCategoryType}
                  control={control}
                  size="small"
                  name="category"
                  label="Data Source Category"
                  error={!!errors.category}
                  helperText={errors.category?.message}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={isApply}
                  control={control}
                  size="small"
                  name="emailVerification"
                  label="Email Verification"
                  error={!!errors.emailVerification}
                  helperText={errors.emailVerification?.message}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={isApply}
                  control={control}
                  size="small"
                  name="badCheck"
                  label="Bad Record Check"
                  error={!!errors.badCheck}
                  helperText={errors.badCheck?.message}
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <GlobalDropdown
                  options={isApply}
                  control={control}
                  size="small"
                  name="dupeCheck"
                  label="Dupe Record Check"
                  error={!!errors.dupeCheck}
                  helperText={errors.dupeCheck?.message}
                  required
                />
              </Grid>
              {watch("dupeCheck") && (
                <Grid item xs={4}>
                  <GlobalInput
                    control={control}
                    size="small"
                    type="number"
                    name="dupeReject"
                    label="Dupe Reject (Hours)"
                    error={!!errors.dupeReject}
                    helperText={errors.dupeReject?.message}
                    required
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Remark"
                  fullWidth
                  {...register("remark")}
                  size="small"
                  multiline
                  rows={4}
                  error={!!errors.remark}
                  helperText={errors.remark?.message}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            position={"fixed"}
            bottom={8}
            sx={{
              backgroundColor: "#fefffe",
              width: "100%",
            }}
          >
            <Grid container spacing={4} sx={{ width: "70vw" }}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  style={{ width: "100%" }}
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  style={{
                    color: "#FF831F",
                    width: "100%",
                    borderColor: "#FF831F",
                    opacity: res.isLoading || res1.isLoading ? 0.8 : 1,
                    pointerEvents:
                      res.isLoading || res1.isLoading ? "none" : "auto",
                  }}
                  onClick={handleSubmit(onFormSubmit)}
                >
                  {res.isLoading || res1.isLoading
                    ? "Wait..."
                    : isEditMode
                    ? "Update"
                    : "Add"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AddNewSource;
