import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  createTheme,
  ThemeProvider,
  styled,
  alpha,
} from "@mui/material/styles";
import {
  Avatar,
  Box,
  CssBaseline,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./Header.css";
import Icon from "@mdi/react";
import {
  mdiViewDashboardOutline,
  mdiDatabaseOutline,
  mdiTextBoxMultipleOutline,
  mdiDatabaseImportOutline,
  mdiTableColumnWidth,
  mdiExportVariant,
} from "@mdi/js";
import { Link, useLocation } from "react-router-dom";
import JwtService from "@services/jwtService";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import SimpleListMenu from "../../../components/ui/MenuHeader";

const theme = createTheme();

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const routes = [
  {
    title: "Dashboard",
    content: "Business Insights at a Glance",
    icon: mdiViewDashboardOutline,
    route: "/dashboard",
  },
  {
    title: "Data Sources",
    content: "Add and Configure Data Sources",
    icon: mdiDatabaseOutline,
    route: "data-sources/manage-data-source",
  },
  {
    title: "Export Data",
    content: "Streamline Data Export Process",
    icon: mdiExportVariant,
    route: "exportData/channel-list",
  },
  {
    title: "File Sources",
    content: "Efficient File Handling",
    icon: mdiTextBoxMultipleOutline,
    route: "fileSource/available-files",
  },
  {
    title: "Map and Import",
    content: "Data Mapping and Import Wizard",
    icon: mdiDatabaseImportOutline,
    route: "mapandimport/map-data-column-and-import",
  },
  {
    title: "Data Store",
    content: "View Data Source Reports",
    icon: mdiTableColumnWidth,
    route: "data-store/data-store-list",
  },

  {
    title: "Reports",
    content: "Insights from Processed Data",
    icon: mdiTableColumnWidth,
    route: "/report/import",
  },
  {
    title: "Raise an issue",
    content: "Service Support and Feedback",
    icon: mdiTableColumnWidth,
    route: "/raise-an-issue",
  },
];

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const actionData = useLocation();

  useEffect(() => {
    handleSelection();
  }, [actionData]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handleNavigation = (route) => {
  //   if (props?.title === "Raise an issue" && props?.route) {
  //     window.open('https://coda.io/form/Tech-Support-Ticket_dNkR-7Dz_g2', "_blank");
  //   } else if (route) {
  //     setTimeout(() => {
  //       setSelectedIndex(index);
  //       popupState.close();
  //     }, 100);
  //   }
  // };

  const [anchorElList, setAnchorElList] = React.useState(null);
  const openList = Boolean(anchorElList);
  const handleListClose = () => {
    setAnchorElList(null);
  };

  const handleSelection = () => {
    switch (true) {
      case /\bdashboard\b/i.test(actionData.pathname):
        setSelectedIndex(0);
        break;
      case /\bdata-sources\b/i.test(actionData.pathname):
        setSelectedIndex(1);
        break;
      case /\bfileSource\b/i.test(actionData.pathname):
        setSelectedIndex(2);
        break;
      case /\bmapandimport\b/i.test(actionData.pathname):
        setSelectedIndex(3);
        break;
      case /\bdata-store\b/i.test(actionData.pathname):
        setSelectedIndex(4);
        break;
      case /\bexportData\b/i.test(actionData.pathname):
        setSelectedIndex(5);
        break;
      case /\breport\b/i.test(actionData.pathname):
        setSelectedIndex(6);
        break;
      // case /\braise-an-issue\b/i.test(actionData.pathname):
      //   window.open('https://coda.io/form/Tech-Support-Ticket_dNkR-7Dz_g2', "_blank");
      //   break;
      //setSelectedIndex(6);
      default:
        setSelectedIndex(0);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar
          sx={{
            backgroundColor: "#06207C",
            paddingLeft: "0px !important",
          }}
        >
          <Box
            sx={{
              width: "62px",
              height: "62px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: " #0000007f",
            }}
          >
            <Link to={"/"}>
              <img
                style={{
                  marginBottom: "6px",
                  marginLeft: "-6px",
                }}
                src="../../images/img_send.svg"
                className="headerIcon"
                alt="send"
              />
            </Link>
          </Box>

          <Typography
            variant="h6"
            color="inherit"
            ml={0}
            noWrap
            style={{ flexGrow: 1 }}
          >
            <div>
              <PopupState
                variant="popover"
                popupId="demo-popup-menu"
                endIcon={<KeyboardArrowDownIcon />}
              >
                {(popupState) => (
                  <React.Fragment>
                    <Button
                      {...bindTrigger(popupState)}
                      startIcon={
                        <img
                          src="../../images/datalab-logo.svg"
                          width={30}
                          height={30}
                          className="headerIcon"
                          alt="send"
                        />
                      }
                      endIcon={<KeyboardArrowDownIcon />}
                      style={{ textTransform: "capitalize", color: "#D4D4D4" }}
                    >
                      Data labs
                    </Button>
                    <Menu
                      {...bindMenu(popupState)}
                      sx={{
                        mt: 2,
                        ml: 0,
                      }}
                    >
                      {routes.map((item, index) => (
                        <MenuItem
                          key={item?.title}
                          onClick={() => {
                            setTimeout(() => {
                              setSelectedIndex(index);
                              popupState.close();
                            }, 100);
                          }}
                          selected={index === selectedIndex}
                          selectedOpacity={5}
                          component={Link}
                          to={
                            item.route === "/raise-an-issue"
                              ? "https://coda.io/form/Tech-Support-Ticket_dNkR-7Dz_g2"
                              : item.route
                          }
                          target={
                            item.route === "/raise-an-issue" ? "_blank" : "_self"
                          }
                          disableRipple
                          disabled={!item?.route}
                          sx={{
                            p: 2,
                          }}
                        >
                          <Grid container spacing={2} alignItems={"center"}>
                            <Grid item>
                              <Icon path={item.icon} size={1} color="#A6B6C8" />
                            </Grid>
                            <Grid
                              item
                              sx={{
                                sm: 10,
                                p: 0,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "center",
                              }}
                            >
                              <Typography fontWeight={600}>
                                {item?.title}
                              </Typography>
                              <Typography>{item?.content}</Typography>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorElList}
                open={openList}
                onClose={handleListClose}
              >
                {routes.map((item, index) => (
                  <MenuItem
                    key={item?.route}
                    onClick={handleListClose}
                    component={Link}
                    to={
                      item.route === "/raise-an-issue"
                        ? "https://coda.io/form/Tech-Support-Ticket_dNkR-7Dz_g2"
                        : item.route
                    }
                    target={
                      item.route === "/raise-an-issue" ? "_blank" : "_self"
                    }
                    disableRipple
                  ></MenuItem>
                ))}
              </StyledMenu>
            </div>
          </Typography>
          {/* <div>
            <SimpleListMenu />
          </div> */}
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              sx={{
                marginTop: "20px !important",
                marginLeft: "6px !important",
              }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                disabled={true}
                onClick={handleClose}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px !important",
                  width: "180px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                Profile &nbsp;&nbsp;&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path d="M21.0082 3C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082ZM20 5H4V19H20V5ZM18 15V17H6V15H18ZM12 7V13H6V7H12ZM18 11V13H14V11H18ZM10 9H8V11H10V9ZM18 7V9H14V7H18Z"></path>
                </svg>
              </MenuItem>
              <MenuItem
                onClick={() => JwtService.logout()}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px !important",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                Logout
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path
                    d="M6.26489 3.80698L7.41191 5.44558C5.34875 6.89247 4 9.28873 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 9.28873 18.6512 6.89247 16.5881 5.44558L17.7351 3.80698C20.3141 5.61559 22 8.61091 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 8.61091 3.68594 5.61559 6.26489 3.80698ZM11 12V2H13V12H11Z"
                    fill="rgba(255,7,7,1)"
                  ></path>
                </svg>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
