import React, {
  useMemo,
  useRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Box, Button, Card, Switch, TextField } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Icon from "@mdi/react";
import {
  mdiPencil,
  mdiToggleSwitch,
  mdiPowerPlugOff,
  mdiDelete,
  mdiShieldKeyOutline,
  mdiCog,
  mdiSetCenter,
} from "@mdi/js";
import CommonFunctions from "../../../utils/CommonFunctions";
import { mdiDownloadBoxOutline } from "@mdi/js";

let userPermissions = JSON.parse(localStorage.getItem("userPermission"));

const AgGridComponent = ({
  setselectedRows,
  rowData,
  columnDefs,
  pagination,
  onRowSelectionChangedHandler,
  height,
  search,
  floatingFilter,
  actions,
  actioncondition,
  performAction,
  pinnedBottomRowData,
  ...rest
}) => {
  const [column, setColumn] = useState();
  const [row, setRow] = useState();
  const [isGridDisplay, setIsGridDisplay] = useState(false);
  const gridRef = useRef();

  const defaultColDef = useMemo(() => {
    return {
      // width: 200,
      flex: 1,
      minWidth: 200,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: false,
      headerClass: "centered-header",
      enableCellSelection: true,
      autoHeaderHeight: true,
      // editable: true,
      // enableRowGroup: true,
      // enablePivot: true,
      // floatingFilter: floatingFilter === false ? false : true,
      // minWidth: 200,
    };
  }, []);

  useEffect(() => {
    userPermissions = JSON.parse(localStorage.getItem("userPermission"));
  }, []);

  const executeActions = (data, action) => {
    if (typeof performAction !== "undefined") performAction(data, action);
  };

  const onRowSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    onRowSelectionChangedHandler(selectedRows);
    if (setselectedRows !== undefined) {
      setselectedRows(selectedRows.length);
    }
  }, []);
  const onBtShowNoRows = useCallback(() => {
    gridRef.current.api.showNoRowsOverlay();
  }, []);

  const onBtHide = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  const actionButtons = useCallback((params) => {
    return (
      <span>
        <div style={{ textAlign: "center" }}>
          {typeof actions !== "undefined" && actions.includes("EDIT") && (
            <Icon
              path={mdiPencil}
              title="Edit"
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "EDIT");
                }
              }}
              size={1}
              style={{
                color: "#233784",
                display:
                  typeof actioncondition !== "undefined"
                    ? params.data[actioncondition?.edit?.key] ===
                      actioncondition?.edit?.value
                      ? "none"
                      : "inline-block"
                    : params.data?.isDeleted
                    ? "none"
                    : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
          {typeof actions !== "undefined" && actions.includes("REGENERATE") && (
            <Icon
              path={mdiShieldKeyOutline}
              title="Re-Generate Key"
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "REGENERATE");
                }
              }}
              size={1}
              style={{
                color: "#ffc412",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
          {typeof actions !== "undefined" && actions.includes("CONFIG") && (
            <Icon
              path={mdiCog}
              title={params.data?.mappedHeader ? "Update Config" : "Set Config"}
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "CONFIG");
                }
              }}
              size={1}
              style={{
                color: params.data?.config ? "blue" : "grey",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
          {typeof actions !== "undefined" && actions.includes("MAPHEADER") && (
            <Icon
              path={mdiSetCenter}
              title={
                params.data?.mappedHeader ? "Update Map Headers" : "Map Headers"
              }
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "MAPHEADER");
                }
              }}
              size={1}
              style={{
                color: params.data?.mappedHeader ? "blue" : "grey",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
          {typeof actions !== "undefined" && actions.includes("ACTIVE") && (
            <Switch
              title={params.data?.isActive ? "Active" : "Inactive"}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
              labelPlacement="bottom"
              color={params.data?.isActive ? "success" : "warning"}
              checked={params.data?.isActive}
              onChange={() => {
                if (
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "ACTIVE");
                }
              }}
              sx={{
                display: params.data?.isDeleted ? "none" : "",
                marginBottom: "20px",
                cursor:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
              disabled={
                !(
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                )
              }
            />
          )}

          {typeof actions !== "undefined" && actions.includes("DELETE") && (
            <Icon
              path={mdiDelete}
              title="Delete"
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Delete Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "DELETE");
                }
              }}
              size={1}
              style={{
                color: "#b71c1c",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Delete Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Delete Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
        </div>
      </span>
    );
  }, []);

  const centerAlignedHeaderComponent = (props) => {
    return <div style={{ textAlign: "center" }}>Action</div>;
  };

  useEffect(() => {
    if (columnDefs && columnDefs.length && columnDefs !== undefined) {
      let col = [...columnDefs];
      if (typeof actions !== "undefined" && actions?.length > 0) {
        col.push({
          field: "Action",
          cellRenderer: actionButtons,
          pinned: "right",
          headerComponent: centerAlignedHeaderComponent,
        });
      }
      setColumn(col);
      setRow(rowData);
    } else {
      setIsGridDisplay(true);
    }
    if (rowData?.length === 0) {
      setIsGridDisplay(true);
    }
  }, [columnDefs, rowData]);

  const handleInputChange = (event) => {
    gridRef.current.api.setQuickFilter(event.target.value);

    if (gridRef.current.api.filterManager.rowModel.rowsToDisplay.length <= 0) {
      onBtShowNoRows();
    } else {
      onBtHide();
    }
  };
  const getRowStyle = useCallback((params) => {
    if (params.node.rowPinned) {
      return { fontWeight: "bold" };
    }
  }, []);
  const csvDownload = () => {
    const Keys = column
      .map((res) => res.field)
      .filter((res) => res !== "Action");
    if (gridRef.current.api.filterManager.rowModel.rowsToDisplay.length) {
      const searchedData =
        gridRef.current.api.filterManager.rowModel.rowsToDisplay.map(
          (res) => res.data
        );
      CommonFunctions.downloadTableData(searchedData, Keys);
    } else {
      CommonFunctions.downloadTableData(row, Keys);
    }
  };

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: height || "62vh",
        width: "100%",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      {search !== false && (
        <Card
          sx={{
            padding: "10px",
            marginBottom: "10px",
            border: "solid 1px #babfc7;",
          }}
        >
          <Box display="flex" justifyContent="space-between">
            {/* <Box></Box> */}
            <Box>
              <TextField
                id="search_Inp"
                label="Search"
                variant="outlined"
                size="small"
                sx={{ paddingRight: "25px" }}
                onInput={handleInputChange}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                // startIcon={<Icon path={mdiDownloadBoxOutline} size={1} />}
                onClick={csvDownload}
                disabled={row && row.length ? false : true}
              >
                Download
              </Button>
            </Box>
          </Box>
        </Card>
      )}

      {((row && row.length) || isGridDisplay) && (
        <AgGridReact
          rowData={row || []}
          columnDefs={column || []}
          defaultColDef={defaultColDef}
          pagination={pagination === false ? pagination : true}
          ref={gridRef}
          animateRows={true}
          paginationPageSize={15}
          enableSorting={true}
          enableFilter={true}
          suppressDragLeaveHidesColumns={true}
          rowSelection={"multiple"}
          suppressSizeToFit={true}
          rowMultiSelectWithClick={true}
          onSelectionChanged={onRowSelectionChanged}
          // onGridReady={onGridReady}
          enableRangeSelection={true} // Enable range selection
          enableRangeHandle={true}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data was found</span>'
          }
          enableCellTextSelection="true"
          pinnedBottomRowData={pinnedBottomRowData}
          getRowStyle={getRowStyle}
          {...rest}
          // copyHeadersToClipboard={true}
          // suppressCopyRowsToClipboard={true}
          // getRowId={getRowId}
          // paginationGoToPage={handlePaginationGoToPage}
          // paginationGetRowCount={paginator?.itemCount}
          // paginationGetRowCount={paginator.itemCount}
          // paginationGetTotalPages={paginator?.pageCount}
          // onSelectionChanged={onRowSelectionChanged}
          // suppressHorizontalScroll={true}
          // headerHeight={150}
        ></AgGridReact>
      )}
    </div>
  );
};

export default AgGridComponent;
