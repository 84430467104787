import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { axiosInstance } from "@services/axiosInstance";
import { baseURL } from "@configs/envConfig";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import { FormatAlignJustify } from "@mui/icons-material";
import CommonFunctions from "../../../../utils/CommonFunctions";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import DatePickerComponent from "../../../../components/common/GlobalDatePicker";
import AgGridComponent from "../../../../components/common/AgGridComponent";

// const staticColumns = [
//   { headerName: "Source Owner", field: "source_owner", filter: true },
//   { headerName: "Datasourceid", field: "datasourceid", filter: true },
//   { headerName: "Source Name", field: "source_name", filter: true },
//   { headerName: "Posted Records", field: "posted_records", filter: true },
//   { headerName: "Records Imported", field: "sucess_records", filter: true },
//   { headerName: "Bad Rejected", field: "bad_records", filter: true },
//   { headerName: "Dupe Rejected", field: "dupe_records", filter: true },
//   { headerName: "Failed Rejected", field: "failed_records", filter: true },
// ];

const DomainWiseCountReport = () => {
  const [gridData, setGridData] = useState([]);
  const [allColumn, setAllColumn] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      sourceId: [],
      fromDate: dayjs().subtract(1, "day"),
      toDate: dayjs(),
    },
    mode: "all",
  });

  const onFormSubmit = (formData) => {
    setIsLoading(true);
    const data = {
      fromDate: dayjs(formData.fromDate.$d).format("YYYY-MM-DD"),
      toDate: dayjs(formData.toDate.$d).format("YYYY-MM-DD"),
    };

    axiosInstance
      .post(`${baseURL}/dl/export/domain-wise/count-report`, { data })
      .then((result) => {
        if (result.data?.data?.output?.length) {
          setGridData(result.data.data.output);
          setAllColumn(result.data.data?.headers);
        } else if (result.data.length === 0) {
          setGridData([]);
          setAllColumn([]);
          dispatch(
            snackbarMessageUpdate({
              open: true,
              color: "warning",
              message: "No Data Found",
            })
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  return (
      <div
        className="ag-theme-alpine"
        style={{ height: "calc(100vh - 235px)" }}
      >
        <Card>
          <Box
            sx={{
              padding: "20px 30px",
              borderBottom: "1px solid #E0E0E0",
            }}
          >
            <Box
              component={"form"}
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Grid container spacing={4} display={"flex"}>
                <Grid item xs={2}>
                  <DatePickerComponent
                    name="fromDate"
                    label="From Date"
                    control={control}
                    minDate={dayjs("2000-01-01")}
                    maxDate={watch("toDate")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <DatePickerComponent
                    name="toDate"
                    label="Date To"
                    control={control}
                    minDate={watch("fromDate")}
                    maxDate={dayjs()}
                  />
                </Grid>
                <Grid item xs={2}>
                  {/* <Controller
                    name="sourceId"
                    control={control}
                    defaultValue={[]}
                    value={selectedOptions}
                    render={({ field }) => {
                      const allSelected =
                        sourceIdList.length === selectedOptions.length;
                      const handleToggleOption = (selectedOps) =>
                        setSelectedOptions(selectedOps);
                      const handleClearOptions = () => setSelectedOptions([]);
                      const handleSelectAll = (isSelected) => {
                        if (isSelected) {
                          setSelectedOptions(sourceIdList);
                        } else {
                          handleClearOptions();
                        }
                      };
                      const handleToggleSelectAll = () => {
                        handleSelectAll && handleSelectAll(!allSelected);
                      };
                      const handleChange = (event, selectedOps, reason) => {
                        if (selectedOps.length > 0) {
                          setSelectedOptions([...selectedOps]);
                        } else {
                          setSelectedOptions([]);
                          setValue("sourceId", [], {
                            shouldTouch: true,
                            shouldDirty: true,
                          });
                        }
                        if (
                          reason === "selectOption" ||
                          reason === "removeOption"
                        ) {
                          if (
                            selectedOps.find(
                              (option) => option.label === "Select All"
                            )
                          ) {
                            handleToggleSelectAll();
                            const result = sourceIdList.filter(
                              (el) => el.label !== "Select All"
                            );
                            return field.onChange(result);
                          } else {
                            handleToggleOption &&
                              handleToggleOption(selectedOps);
                            return field.onChange(selectedOps);
                          }
                        } else if (reason === "clear") {
                          handleClearOptions && handleClearOptions();
                        }
                      };
                      // const handleAutocompleteChange = (event, newValue,reason) => {
                      //   const uniqueValues = newValue.filter(
                      //     (value, index, self) =>
                      //       index ===
                      //       self.findIndex((v) => v.label === value.label)
                      //   );
                      //   field.onChange(uniqueValues);
                      // };
                      return (
                        <Autocomplete
                          multiple
                          limitTags={2}
                          // includeInputInList
                          size="small"
                          id="tags-outlined"
                          value={selectedOptions}
                          disableCloseOnSelect
                          options={sourceIdList}
                          getOptionLabel={(option) => option?.label}
                          // filterSelectedOptions
                          filterOptions={(options, params) => {
                            return [
                              { label: "Select All", value: "" },
                              ...options,
                            ];
                          }}
                          onChange={handleChange}
                          sx={{
                            width: "100%",
                            mr: 1,
                            "& .MuiAutocomplete-inputRoot": {
                              flexWrap: "nowrap !important",
                              overflow: "hidden",
                            },
                            "& .MuiAutocomplete-endAdornment": {
                              right: "0px !important",
                              background: "#FFFFFF",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              label="Data Source List"
                              error={!!errors.sourceId}
                              helperText={errors.sourceId?.message}
                            />
                          )}
                          renderOption={(props, option, { selected }) => {
                            const selectAllProps =
                              option.label === "Select All"
                                ? { checked: allSelected }
                                : {};
                            return (
                              <li {...props}>
                                <Checkbox
                                  checked={selected}
                                  {...selectAllProps}
                                  style={{ marginRight: 8 }}
                                />
                                {option.label}
                              </li>
                            );
                          }}
                        />
                      );
                    }}
                  /> */}
                </Grid>
                <Grid item xs={6} display={"flex"} justifyContent={"end"}>
                  <Button
                    variant="outlined"
                    type="submit"
                    onClick={handleSubmit(onFormSubmit)}
                    sx={{
                      maxHeight: "2.5rem",
                      mr: "10px",
                    }}
                  >
                    Generate Report
                  </Button>
                  {/* {columnDef.length > 0 && (
                    <Button
                      variant="outlined"
                      onClick={() => handlePersantageValues(isPercentage)}
                      sx={{
                        maxHeight: "2.5rem",
                        mr: "10px",
                      }}
                    >
                      {isPercentage
                        ? "Convert To Number"
                        : "Convert To Percentage"}
                    </Button>
                  )} */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
        {isLoading && <CircularProgressLoading />}

        {!isLoading && (
          <Box
            sx={{
              pt: 3,
            }}
          >
            <AgGridComponent
              columnDefs={allColumn}
              rowData={gridData}
              pagination={true}
              floatingFilter={false}
              paginationPageSize={10}
              onRowSelectionChangedHandler={(arg) => console.log(arg)}
              rowMode
            />
          </Box>
        )}
      </div>
  );
};

export default DomainWiseCountReport;
