import { Box } from "@mui/material";
import React from "react";
import PageHeader from "../../components/ui/pageHeader/PageHeader";
import { Outlet } from "react-router";

const pageHeaderContent = {
  title: "Map and import",
  description: "Map data coloumns and Import",
  navButtons: [
    {
      name: "Map Import Data Columns",
      route: "/mapandimport/map-data-column-and-import",
    },
    { name: "Import Raw Data", route: "/mapandimport/import-raw-data" },
  ],
};
const MapAndImport = () => {
  return (
    <Box pt={0} pl={0}>
      <>
        <PageHeader
          title={pageHeaderContent.title}
          description={pageHeaderContent?.description}
          navButtons={pageHeaderContent?.navButtons}
        />
        <Outlet />
      </>
    </Box>
  );
};

export default MapAndImport;
