import React from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../services/axiosInstance";
import { useEffect } from "react";
import { baseURL } from "@configs/envConfig";

const Logout = () => {
  const navigate = useNavigate();

  const getRevokToken = () => {
    const data = {
      clientName: "datalabs",
    };
    axiosInstance
      .post(`${baseURL}/secured/revoke-token`, { data })
      .then((res) => {
        if (res?.data?.data === "Token revoked successfully") {
          localStorage.clear();
        }
        window.location.href = "/";
      });
  };
  useEffect(() => {
    getRevokToken();
  }, []);

  return <div></div>;
};

export default Logout;
