import React, { useState } from "react";
import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import { mdiTableHeadersEye } from "@mdi/js";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import { Tdata1 } from "@store/static-data/TableData";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { baseURL } from "@configs/envConfig";
import { axiosInstance } from "@services/axiosInstance";
import { useNavigate, Navigate } from "react-router-dom";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import CircularProgressLoading from "../../../../components/loaders/CircularProgressLoading";
import { useSelector } from "react-redux";
import { useGetApiCallQuery } from "../../../../services/apiQueryServices";
import { updateImportedDataTablesList } from "../../../../store/FileSourceModule/ImportedDataTableModule/TableGridSlice";

const ImportedDataTables = () => {
  const [inputValue, setInputValue] = useState("");

  const [rowData, setRowData] = useState([]);
  // const [gridData, setGridData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [columnDef, setColumnDef] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Fetching Data from Store
  const GridData = useSelector((state) => state.importedDataTablesList.data);

  //calling gridData api
  const GridDataApi = useGetApiCallQuery({
    endPoint: "dl/file-source/import-file/details",
    method: "GET",
    headers: {},
  });

  useEffect(() => {
    GridDataApi.refetch();
    if (GridDataApi?.isSuccess && GridDataApi?.data?.data !== null) {
      dispatch(
        updateImportedDataTablesList(
          [...GridDataApi?.data?.data?.data].reverse()
        )
      );
    }
  }, [GridDataApi?.data, GridDataApi?.isSuccess]);

  // const getPageData = async (pageNo, limit) => {
  //   await axiosInstance
  //     .get(`${baseURL}/dl/file-source/import-file/details`, {
  //       query: {},
  //       options: {
  //         page: pageNo,
  //         limit,
  //         pagination: false,
  //         options: {},
  //       },
  //     })
  //     .then((res) => {
  //       setIsLoading(false);
  //       if (res.data.data.data.length) {
  //         setGridData(res.data.data.data.reverse());
  //       }
  //     })
  //     .catch((error) => {
  //       errorCallback(error);
  //       setIsLoading(false);
  //     });
  // };

  // const loadData = () => {
  //   setIsLoading(true);
  //   getPageData(1, 10);
  // };
  // useEffect(() => {
  //   loadData();
  // }, []);

  const errorCallback = (error) => {
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response.data.status.value,
      })
    );
  };

  useEffect(() => {
    if (GridData && GridData.length) {
      const keys = Object.keys(GridData[0]);
      // const column = keys?.map((item, index) => {
      //   return { field: item, filter: true };
      // });
      const column = [...GridDataApi?.data?.data?.headers];

      column.splice(1, 0, {
        field: "View Data Table",
        cellRenderer: actionButtons,
      });

      setColumnDef(column);
    }
  }, [GridData]);

  const actionButtons = (params) => {
    return (
      <span>
        <>
          <Icon
            path={mdiTableHeadersEye}
            onClick={() => showDoument(params.data)}
            size={1}
          />
        </>
      </span>
    );
  };

  const showDoument = (data) => {
    navigate(`/fileSource/imported-data-tables/${data?.tableName}`);
  };
  const keys = Object.keys(Tdata1.data[0]);
  const coloum = keys.map((item, index) => {
    return {
      headerName: item,
      field: item,
      filter: true,
    };
  });
  if (GridDataApi?.isLoading) return <CircularProgressLoading />;
  return (
    <Box
      sx={{
        padding: "20px",
      }}
    >
      <AgGridComponent
        rowData={GridData}
        columnDefs={columnDef}
        pagination={true}
        paginationPageSize={15}
        onRowSelectionChangedHandler={(arg) => console.log(arg)}
      />
    </Box>
  );
};

export default ImportedDataTables;
