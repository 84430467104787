import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const feedExecutionList = createSlice({
  name: "feedExecutionList",
  initialState,
  reducers: {
    updateFeedExecutionList: (state, action) => {
      state.tableData = action.payload;
    },
    updateTableRow: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    updateSourceIdFeedExecutionList: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex(
        (data) => data?.id === updatedRow?.id
      );
      state.tableData.data[index]["sourceId"] = updatedRow?.sourceId;
    },
    addTableRow: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    removeTableRow: (state, action) => {
      state.tableData = state.tableData.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  updateFeedExecutionList,
  updateTableRow,
  updateSourceIdFeedExecutionList,
  addTableRow,
  removeTableRow,
} = feedExecutionList.actions;
export default feedExecutionList.reducer;
