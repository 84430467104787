import React, { useEffect, useRef, useState } from "react";
import { init } from "echarts";
import "echarts-gl";

const labelOption = {
  show: false,
  position: "top",
  distance: 15,
  // align: app.config.align,
  verticalAlign: "center",
  // rotate: app.config.rotate,
  rotate: 90,
  formatter: "{c}",
  fontSize: 12,
  // {name|{a}}
  rich: {
    name: {},
  },
};
const SecondDashboard = ({ data, title, mode }) => {
  const [graphData, setGraphData] = useState({});
  const [theme, setTheme] = useState("");
  const chartRef = useRef(null);
  let options = {};

  useEffect(() => {
    setTheme(mode);
  }, [mode]);
  useEffect(() => {
    if (data && Object.keys(data)?.length) {
      data?.series.map((res) => {
        res["label"] = labelOption;
      });
      setGraphData(data);
    }
  }, [data]);

  useEffect(() => {
    const chart = init(chartRef.current, theme);
    const handleResize = () => {
      chart.resize();
    };
    window.addEventListener("resize", handleResize);
    if (!data || Object.keys(data)?.length === 0) {
      chart.showLoading();
      chart.setOption({
        graphic: [
          {
            type: "text",
            left: "center",
            top: "middle",
            style: {
              text: "No data found",
              textAlign: "center",
              fill: "#999",
              fontSize: 16,
              fontWeight: "bold",
            },
          },
        ],
      });
      setTimeout(() => {
        chart.hideLoading();
      }, 4000);
    } else {
      chart.hideLoading();
    }
    options = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: graphData?.legend,
        left: "center",
        bottom: 12,
      },
      title: [
        {
          // top: "100%",
          right: "60%",
          left: "center",
          text: title,
        },
      ],
      toolbox: {
        show: true,
        orient: "vertical",
        left: "right",
        // top: "center",
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar", "stack"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      xAxis: [
        {
          type: "category",
          axisTick: { show: false },
          data: graphData?.xaxis,
        },
      ],
      yAxis: [
        {
          type: "value",
          // axisLabel: {
          //   show: false,
          // },
        },
      ],
      series: graphData?.series,
      dataZoom: [
        {
          type: "slider",
          show: graphData?.xaxis?.length > 15,
          bottom: 0,
          start: 0,
          end: 100,
          height: "15px",
        },
      ],
    };
    chart.setOption(options);

    return () => {
      chart.dispose();
    };
  }, [theme, graphData]);
  return (
    // <div style={{ width: "100%", height: "100%" }}>
    //   {Object.keys(graphData).length > 0 && (
    <div ref={chartRef} style={{ width: "100%", height: "100%" }}></div>
    //   )}
    // </div>
  );
};

export default SecondDashboard;
