import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const destinationOwner = createSlice({
  name: "destinationOwner",
  initialState,
  reducers: {
    updateDestinationOwner: (state, action) => {
      state.tableData = action.payload;
    },
    updateTableRow: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex(
        (data) => data?.id === updatedRow?.id
      );
      state.tableData.data[index] = updatedRow;
    },
    addTableRow: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    removeTableRow: (state, action) => {
      state.tableData = state.tableData.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
    updateDestinationOwner,
  updateTableRow,
  addTableRow,
  removeTableRow,
} = destinationOwner.actions;
export default destinationOwner.reducer;
