import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import NavCard from "../../components/ui/navcard/NavCard";

const Homepage = () => {
  const { user } = useSelector((state) => state?.user);

  const routes = [
    {
      title: "Dashboard",
      content: "Business Insights at a Glance",
      icon: "images/dashbored.svg",
      route: "/dashboard",
    },
    {
      title: "Data Sources",
      content: "Add and Configure Data Sources",
      icon: "images/data-source.svg", 
      route: "/data-sources/manage-data-source",
    },
    {
      title: "File Sources",
      content: "Efficient File Handling",
      icon: "images/file-source.svg",
      route: "/fileSource/available-files",
    },
    {
      title: "Export Data",
      content: "Streamline Data Export Process", 
      icon: "images/export.svg",
      route: "/exportData/channel-list",
    },
    {
      title: "Map and Import",
      content: "Data Mapping and Import Wizard", 
      icon: "images/map-n-import.svg", 
      route: "/mapandimport/map-data-column-and-import",
    },
    {
      title: "Data Store",
      content: "View Data Source Reports", 
      icon: "images/data-source.svg",
      route: "/data-store/data-store-list",
    },

    {
      title: "Reports",
      content: "Insights from Processed Data",
      icon: "images/img_music_indigo_900.svg",
      route: "/report/import",
    },
    {
      title: "Raise an issue",
      content: "Service Support and Feedback", 
      icon: "images/img_music.svg",
       route: "/raise-an-issue",
    },
  ];

  const gridDisabled = {
    // display: "flex",
    // justifyContent: "space-around",
    // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.08)",
    // cursor: 'pointer',
    // padding:'2px 0',
    // "&:hover": {
    //   border: "1px solid #b8c7ff",
    //   boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.20)",
    //   padding:0,
    // },
    ".grid-container": {
      pointerEvents: "none",
      opacity: 0.4,
      filter: "grayscale(100%)",
    },
  };

  return (
    <>
      {/* <Header /> */}
      <Container maxWidth="md">
        <Box component="section" pb={2} pt={8}>
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Typography className="dashboard_sub_text">
                {/* Hi {user?.data?.username} */}
                Hi {user?.name}
              </Typography>
              <Typography className="dashboard_head_text">
                Welcome to Data Labs
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box component="section">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: { xs: 5, md: 10 },
            }}
          >
            <Grid container spacing={2} sx={{ ...gridDisabled }}>
              {routes.map((item, index) => (
                <Grid
                  key={item.title}
                  item
                  sm={6}
                  xs={12}
                  className={
                    item.route ? "grid-container-disabled" : "grid-container"
                  }
                >
                  <NavCard
                    title={item.title}
                    content={item.content}
                    icon={item.icon}
                    route={item.route}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Homepage;
