import React, { useCallback, useEffect, useRef, useState } from "react";
import AgGridComponent from "@components/common/AgGridComponent";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import { baseURL } from "@configs/envConfig";
import Icon from "@mdi/react";
import { mdiPencil } from "@mdi/js";
import {
  Autocomplete,
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useMediaQuery,
  useTheme,
  Checkbox,
} from "@mui/material";
import { useDispatch } from "react-redux";
import AddExecutionList from "../../../../../../Models/AddFeedExecutionListForm";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { axiosInstance } from "@services/axiosInstance";

import CardList from "../../../../../../components/common/GlobalDragDropComponent";
import { CheckBox } from "@mui/icons-material";
import AddButton from "../../../../../../components/common/AddButton";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../services/apiQueryServices";
import { useSelector } from "react-redux";
import {
  updateFeedExecutionList,
  updateSourceIdFeedExecutionList,
} from "../../../../../../store/ExportDataModule/FeedExecutionListSlice";
import { addFeedListQuery } from "../../../../../../store/ExportDataModule/FeedExecutionListSlice/FeedListSlice";

const FeedExecutionList = () => {
  const theme = useTheme();

  const [rowSelected, setRowSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaveChanges, setIsSaveChanges] = useState(false);
  const [feedList, setFeedList] = useState([]);
  const [isCardDisplay, setIsCardDisplay] = useState(true);
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [cardListData, setCardListData] = useState([]);
  const [preCardListData, setPreCardListData] = useState([]);
  const [postCardListData, setPostCardListData] = useState([]);
  const [changedSourceId, setChangedSourceId] = useState([]);

  const TableData = useSelector((state) => state.feedExecutionList?.tableData);

  const FeedList = useSelector((state) => state.feedList?.data);

  const { data, refetch, isError, isFetching, isLoading, isSuccess } =
    useGetApiCallQuery({
      endPoint: "dl/export/data-source-execution/details",
      method: "POST",
      data: {
        query: {},
        options: {
          page: 1,
          limit: 10,
          pagination: false,
          options: {},
        },
      },
      headers: {},
    });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isSuccess && data?.data !== null) {
      dispatchData();
    }
  }, [data, isFetching, isSuccess]);

  const dispatchData = useCallback(() => {
    if (isSuccess) {
      const tableData = {
        data: [...data?.data?.data].reverse(),
        headers: data?.data?.headers,
      };
      dispatch(updateFeedExecutionList(tableData));
    }
  }, [isSuccess, data]);

  const [apiCall1, res1] = useApiCallMutation();
  const [getApiCall, res2] = useApiCallMutation();

  useEffect(() => {
    if (res2.isSuccess && res2?.data?.data) {
      dispatch(addFeedListQuery([...res2.data.data].reverse()));
    } else if (res2?.data?.data === null) {
      dispatch(addFeedListQuery([]));
    }
  }, [res2]);
  useEffect(() => {
    if (res1.isSuccess) {
      dispatch(
        updateSourceIdFeedExecutionList({
          id: res1?.data?.data?.id,
          sourceId: res1?.data?.data?.sourceId,
        })
      );
      successCallback(res1);
      getFeedList();
    }
  }, [res1]);

  const getFeedList = () => {
    getApiCall({
      endPoint: `dl/export/data-source/list-items`,
      method: "GET",
    });
  };
  const successCallback = (response) => {
    handleClose();
    const status = response?.data?.status;
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status?.value,
      })
    );
  };
  const errorCallback = (error) => {
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response.data.status.value,
      })
    );
  };

  const handleActions = (data, action) => {
    switch (action) {
      case "EDIT":
        setIsEditMode(true);
        setRowSelected(data);
        getFeedList();
        break;
    }
  };

  const openForm = () => {
    setOpen(true);
    setRowSelected({});
  };

  const handleClose = () => {
    setRowSelected({});
    setCardListData([]);
    setPreCardListData([]);
    setPostCardListData([]);
    setChangedSourceId([]);
    setIsEditMode(false);
  };

  const handleCardPositionChange = (result) => {
    setIsSaveChanges(true);
    let SourceId = [];
    if (changedSourceId.length) {
      SourceId = changedSourceId;
    } else {
      SourceId = cardListData.map((res) => res.title);
    }
    const element = SourceId[result.source.index];
    SourceId.splice(result.source.index, 1);
    SourceId.splice(result.destination.index, 0, element);
    setChangedSourceId(SourceId);
  };

  const handleRemoveCard = (cardList) => {
    if (cardList.length > 0) {
      setIsSaveChanges(true);
      const SourceId = cardList.map((res) => res.title);
      setChangedSourceId([...SourceId]);
      setPreCardListData([...cardList]);
      setCardListData([...cardList]);
      const validTitles = new Set(cardList.map((item) => item.title));
      const filteredArray = postCardListData.filter((item) =>
        validTitles.has(item.value)
      );
      setPostCardListData(filteredArray);
    }
  };

  const handleSaveChanges = () => {
    const data = {
      sourceId: changedSourceId,
    };

    apiCall1({
      endPoint: `dl/export/data-source-execution/update/${rowSelected?.id}`,
      method: "PUT",
      data: { data },
    });
  };

  useEffect(() => {
    updateCardListData();
  }, [rowSelected]);

  const updateCardListData = () => {
    let cards = [];
    if (rowSelected.sourceId) {
      for (const [index, id] of rowSelected.sourceId.entries()) {
        cards.push({ id: `${index}`, title: id });
      }
    }
    setCardListData(cards);
    setPreCardListData(cards);
  };

  const handleFeedValueChange = (obj) => {
    // setIsCardDisplay(false);
    setPostCardListData(obj);
  };
  const getOptionDisabled = (option) => {
    if (changedSourceId.includes(option.value)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (postCardListData.length) {
      const changingArray = [];
      postCardListData.map((res, index) => {
        const a = preCardListData.findIndex((user) => user.title === res.value);

        if (a === -1) {
          changingArray.push({
            id: `${res.value}`,
            title: res.value,
          });
        }
      });
      setCardListData([...preCardListData, ...changingArray]);
    } else {
      if (preCardListData.length) {
        setCardListData([...preCardListData]);
      }
    }
    setIsCardDisplay(true);
  }, [postCardListData]);

  useEffect(() => {
    if (cardListData.length) {
      const SourceId = cardListData.map((res) => res.title);
      setChangedSourceId([...SourceId]);
    }
  }, [cardListData]);

  if (isLoading) return <CircularProgressLoading />;

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "absolute",
          top: 140,
          right: 16,
        }}
      >
        <AddButton onclick={openForm}>Add Feed Into Execution List</AddButton>
      </Box>
      {open && (
        <AddExecutionList
          defaultValues={rowSelected}
          isOpen={open}
          setOpen={setOpen}
          name={"Add Data Source Owners"}
          // loadData={loadData}
        ></AddExecutionList>
      )}

      <AgGridComponent
        rowData={TableData?.data}
        columnDefs={TableData?.headers}
        onRowSelectionChangedHandler={(arg) => {
          console.log(arg);
        }}
        actions={["EDIT"]}
        actioncondition={{
          edit: {
            key: "feedType",
            value: "single",
          },
        }}
        performAction={handleActions}
        pagination={true}
        paginationPageSize={10}
        rowMode
      />

      <div>
        <Dialog
          fullScreen={fullScreen}
          open={isEditMode}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth={"100%"}
          fullWidth={true}
        >
          <DialogTitle id="responsive-dialog-title">
            Destination Source Details
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container spacing={2} padding={2}>
                <Grid item xs={4}>
                  <div>Feed Type</div>
                  <div>
                    <b>Multi-Sync</b>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    multiple
                    limitTags={1}
                    includeInputInList
                    value={postCardListData}
                    options={FeedList || []}
                    size="small"
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    onChange={(e, val) => handleFeedValueChange(val)}
                    // getOptionDisabled={getOptionDisabled}
                    sx={{
                      width: "100%",
                      mr: 1,
                      "& .MuiAutocomplete-inputRoot": {
                        flexWrap: "nowrap !important",
                        overflow: "hidden",
                      },
                      "& .MuiAutocomplete-endAdornment": {
                        right: "0px !important",
                        background: "#FFFFFF",
                        overflow: "hidden",
                      },
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Feeds"
                        // placeholder="Favorites"
                      />
                    )}
                    // style={{ width: 500 }}
                  />
                </Grid>
                <Grid item xs={12} mt={32}>
                  <div>Existing List</div>
                  <div>
                    {rowSelected.sourceId &&
                      rowSelected.sourceId.map((sourceId) => (
                        <span>
                          <b>{sourceId}</b>,{""}
                        </span>
                      ))}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {/* {isCardDisplay && cardListData.length !== 0 && (
                    <CardList
                      cards={cardListData}
                      onCardPositionChange={handleCardPositionChange}
                      onRemoveCard={handleRemoveCard}
                    />
                  )} */}
                  <CardList
                    cards={cardListData}
                    onCardPositionChange={handleCardPositionChange}
                    onRemoveCard={handleRemoveCard}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="outlined"
              onClick={handleSaveChanges}
              style={{
                opacity: res1.isLoading ? 0.8 : 1,
                pointerEvents: res1.isLoading ? "none" : "auto",
                cursor: res1.isLoading ? "not-allowed" : "pointer",
              }}
              disabled={cardListData.length > 1 ? false : true}
            >
              {res1.isLoading ? "Wait..." : "Save Changes"}
            </Button>
            <Button onClick={handleClose} variant="outlined" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default FeedExecutionList;
