import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const dataHeader = createSlice({
  name: "dataHeader",
  initialState,
  reducers: {
    updateDataHeaderTableData: (state, action) => {
      state.tableData = action.payload;
    },
  },
});

export const { updateDataHeaderTableData } = dataHeader.actions;
export default dataHeader.reducer;
