import React, { useCallback, useEffect, useState } from "react";
import AgGridComponent from "@components/common/AgGridComponent";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import { baseURL } from "@configs/envConfig";
import Icon from "@mdi/react";
import { mdiPencil, mdiPowerPlug, mdiPowerPlugOff, mdiDelete } from "@mdi/js";
import { Box, Button, Switch } from "@mui/material";
import { useDispatch } from "react-redux";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import AddEditNewDataSource from "../../../../../../Models/AddDestinationOwner";
import GlobalConfirmationPopup from "../../../../../../components/common/GlobalConfirmationPopup";
import { axiosInstance } from "@services/axiosInstance";
import AddButton from "../../../../../../components/common/AddButton";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../../../services/apiQueryServices";
import { useSelector } from "react-redux";
import {
  updateDestinationOwner,
  updateTableRow,
} from "../../../../../../store/ExportDataModule/DestinationOwnerSlice";

const DestinationOwner = () => {
  const [rowSelected, setRowSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const dispatch = useDispatch();

  const { data, refetch, isError, isFetching, isLoading, isSuccess } =
    useGetApiCallQuery({
      endPoint: "dl/export/source-owner/details", // Replace with your API endpoint
      method: "POST",
      data: {
        query: {},
        options: {
          page: 1,
          limit: 10,
          pagination: false,
          options: {},
        },
      },
      headers: {}, // Replace with any additional headers if needed
    });

  const TableData = useSelector((state) => state.destinationOwner.tableData);

  const [apiCall1, res2] = useApiCallMutation();
  const [apiCall3, res3] = useApiCallMutation();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    dispatchData();
  }, [data, isFetching, isSuccess]);

  useEffect(() => {
    if (res2.isSuccess) {
      successCallback(res2?.data);
      dispatch(updateTableRow(res2?.data?.data));
    }
    if (res3.isSuccess) {
      successCallback(res3?.data);
      dispatch(updateTableRow(res3?.data?.data));
    }
  }, [res2, res3]);

  const dispatchData = useCallback(() => {
    if (isSuccess) {
      const tableData = {
        data: [...data?.data?.data].reverse(),
        headers: data?.data?.headers,
      };
      dispatch(updateDestinationOwner(tableData));
    }
  }, [isSuccess, data]);

  const successCallback = (response) => {
    const status = response?.status;
    // loadData();
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };

  const errorCallback = (error) => {
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response.data.status.value,
      })
    );
  };

  const openForm = () => {
    setOpen(true);
    setIsEditMode(false);
    setRowSelected({});
  };

  const handleConfirm = (remark) => {
    if (isActive) {
      setIsActive(false);
      const data = {
        remark: remark,
        isActive: true,
      };

      apiCall1({
        endPoint: `dl/export/source-owner/active-inactive/${rowSelected?.id}`, // Replace with your API endpoint
        method: "PUT",
        data: { data },
      });
    } else if (isInactive) {
      setIsInactive(false);
      const data = {
        remark: remark,
        isActive: false,
      };

      apiCall1({
        endPoint: `dl/export/source-owner/active-inactive/${rowSelected?.id}`, // Replace with your API endpoint
        method: "PUT",
        data: { data },
      });
    } else if (isDelete) {
      setIsDelete(false);
      const data = {
        data: {
          remark: remark,
        },
      };

      apiCall3({
        endPoint: `dl/export/source-owner/delete/${rowSelected?.id}`,
        method: "DELETE",
        data: { data },
      });
    }
    setShowConfirmation(false);
  };

  const handleCancel = (data) => {
    setShowConfirmation(false);
  };

  const handleActions = (data, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(data);
        setIsEditMode(true);
        setOpen(true);
        break;
      case "ACTIVE":
        setRowSelected(data);
        if (data.isActive) {
          setIsInactive(true);
          setPopupTitle("Are you sure you want to Inactive?");
          setShowConfirmation(true);
        } else if (!data.isActive) {
          setIsActive(true);
          setPopupTitle("Are you sure you want to Active?");
          setShowConfirmation(true);
        }
        break;
      case "DELETE":
        setRowSelected(data);
        setIsDelete(true);
        setPopupTitle("Are you sure you want to Delete?");
        setShowConfirmation(true);
        break;
      default: {
      }
    }
  };

  if (isLoading) return <CircularProgressLoading />;

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "absolute",
          top: 140,
          right: 16,
        }}
      >
        <AddButton onclick={openForm}>Add New Destination Owner</AddButton>
      </Box>
      {open && (
        <AddEditNewDataSource
          defaultValues={rowSelected}
          isOpen={open}
          setOpen={setOpen}
          name={"Add Data Source Owners"}
          loadData={refetch}
          isEditMode={isEditMode}
        ></AddEditNewDataSource>
      )}
      <GlobalConfirmationPopup
        open={showConfirmation}
        title={popupTitle}
        // message="Are you sure you want to proceed?"
        onConfirm={handleConfirm}
        onClose={handleCancel}
      />
      {/* {gridData && ( */}
      <AgGridComponent
        rowData={TableData?.data || []}
        columnDefs={TableData?.headers || []}
        actions={["EDIT", "ACTIVE", "DELETE"]}
        performAction={handleActions}
        onRowSelectionChangedHandler={(arg) => {
          setIsEditMode(true);
        }}
        pagination={true}
        paginationPageSize={10}
        rowMode
      />
    </React.Fragment>
  );
};

export default DestinationOwner;
