import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { axiosInstance } from "@services/axiosInstance";
import { baseURL } from "@configs/envConfig";
import CircularProgressLoading from "@components/loaders/CircularProgressLoading";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useDispatch } from "react-redux";
import DatePickerComponent from "../../../../components/common/GlobalDatePicker";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../../../services/apiQueryServices";

const SourceDuplicateReport = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [columnDef, setColumnDef] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [sourceIdList, setSourceIdList] = useState([]);
  const [allColumn, setAllColumn] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPercentage, setIsPercentage] = useState(false);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      sourceId: [],
      fromDate: dayjs().subtract(1, "day"),
      toDate: dayjs(),
    },
    mode: "all",
  });

  const [sourceIdCall, sourceIdData] = useApiCallMutation();
  const [apiCall, res] = useApiCallMutation();

  useEffect(() => {
    if (sourceIdData.isSuccess) {
      setSourceIdList(sourceIdData?.data?.data);
    }
  }, [sourceIdData]);

  useEffect(() => {
    const data = {
      ownerId: "",
    };
    sourceIdCall({
      endPoint: `dl/map-and-import/source/list-items`,
      method: "POST",
      data: { data },
    });
  }, []);

  const onFormSubmit = (formData) => {
    setIsLoading(true);
    const data = {
      fromDate: dayjs(formData.fromDate.$d).format("YYYY-MM-DD"),
      toDate: dayjs(formData.toDate.$d).format("YYYY-MM-DD"),
      sourceId: formData.sourceId.map((res) => res.value),
    };
    apiCall({
      endPoint: `dl/import/email-verified/count-report`,
      method: "POST",
      data: { data },
    }); 

    // axiosInstance
    //   .post(`${baseURL}/dl/import/email-verified/count-report`, { data })
    //   .then((result) => {
    //     if (result.data?.data?.data?.length) {
    //       setGridData(result.data.data.data);
    //       setAllColumn(result.data.data?.headers);
    //     } else if (result.data.length === 0) {
    //       setGridData([]);
    //       setAllColumn([]);
    //       dispatch(
    //         snackbarMessageUpdate({
    //           open: true,
    //           color: "warning",
    //           message: "No Data Found",
    //         })
    //       );
    //     }
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     setIsLoading(false);
    //   });

  };

  useEffect(() => {
    if (res.isSuccess) {
      if (res.data?.error) {
        setGridData([]);
        setAllColumn([]);
        dispatch(
          snackbarMessageUpdate({
            open: true,
            color: "red",
            message: "Error Found",
          })
        );
      } else {
        setIsLoading(false);
        setGridData(res?.data?.data?.data);
        setAllColumn(res?.data?.data?.headers);
      }
    } else {
      setGridData([]);
      setAllColumn([]);
      dispatch(
        snackbarMessageUpdate({
          open: true,
          color: "warning",
          message: "No Data Found",
        })
      );
      //setIsLoading(false);
    }
    
  }, [res]); 

  // const getSourceId = async () => {
  //   const data = {
  //     ownerId: "",
  //   };
  //   await axiosInstance
  //     .post(`${baseURL}/dl/map-and-import/source/list-items`, { data })
  //     .then((result) => {
  //       setSourceIdList(result.data.data);
  //     });
  // };
  useEffect(() => {
    if (allColumn.length) {
      handlePersantageValues();
    }
  }, [allColumn, isPercentage]);

  const handlePersantageValues = () => {
    if (!isPercentage) {
      const finalColumn = allColumn.filter(
        (res) =>
          res.field !== "validSentCountPercent" &&
          res.field !== "inValidSentCountPercent"
      );
      setColumnDef(finalColumn);
    } else {
      const finalColumn = allColumn.filter(
        (res) =>
          res.field !== "validSentCount" && res.field !== "inValidSentCount"
      );

      setColumnDef(finalColumn);
    }
    // setIsPercentage(!isPercent);
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "calc(100vh - 235px)" }}>
      <Card>
        <Box
          sx={{
            padding: "20px 30px",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          <Box
            component={"form"}
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Grid container spacing={4} display={"flex"}>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="fromDate"
                  label="From Date"
                  control={control}
                  minDate={dayjs("2000-01-01")}
                  maxDate={watch("toDate")}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePickerComponent
                  name="toDate"
                  label="Date To"
                  control={control}
                  minDate={watch("fromDate")}
                  maxDate={dayjs()}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="sourceId"
                  control={control}
                  render={({ field }) => {
                    const allSelectedDataSource =
                      sourceIdList.length === selectedOptions.length;
                    const handleToggleOption = (selectedOps) =>
                      setSelectedOptions(selectedOps);
                    const handleClearOptions = () => setSelectedOptions([]);
                    const handleSelectAll = (isSelected) => {
                      if (isSelected) {
                        setSelectedOptions(sourceIdList);
                      } else {
                        handleClearOptions();
                      }
                    };
                    const handleToggleSelectAll = () => {
                      const allSelected =
                        selectedOptions.length === sourceIdList.length;
                      setSelectedOptions(allSelected ? [] : sourceIdList);
                    };
                    const handleChange = (event, selectedOps, reason) => {
                      if (
                        reason === "selectOption" ||
                        reason === "removeOption"
                      ) {
                        if (
                          selectedOps.find(
                            (option) => option.label === "Select All"
                          )
                        ) {
                          handleToggleSelectAll();
                          const result = sourceIdList.filter(
                            (el) => el.label !== "Select All"
                          );
                          return field.onChange(result);
                        } else {
                          handleToggleOption(selectedOps);
                          return field.onChange(selectedOps);
                        }
                      } else if (reason === "clear") {
                        handleClearOptions();
                        //  handleToggleOption(selectedOps);
                        return field.onChange([]);
                      }
                    };
                    const handleAutocompleteChange = (event, newValue) => {
                      const uniqueValues = newValue.filter(
                        (value, index, self) =>
                          index ===
                          self.findIndex((v) => v.label === value.label)
                      );
                      field.onChange(uniqueValues);
                    };
                    return (
                      <Autocomplete
                        multiple
                        limitTags={1}
                        includeInputInList
                        size="small"
                        id="tags-outlined"
                        value={selectedOptions}
                        disableCloseOnSelect
                        options={sourceIdList}
                        getOptionLabel={(option) => option?.label}
                        filterOptions={(options, params) => {
                          return [
                            { label: "Select All", value: "" },
                            ...options.filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(params.inputValue.toLowerCase())
                            ),
                          ];
                        }}
                        onChange={handleChange}
                        sx={{
                          width: "100%",
                          mr: 1,
                          "& .MuiAutocomplete-inputRoot": {
                            flexWrap: "nowrap !important",
                            overflow: "hidden",
                          },
                          "& .MuiAutocomplete-endAdornment": {
                            right: "0px !important",
                            background: "#FFFFFF",
                          },
                        }}
                        renderOption={(props, option, { selected }) => {
                          const selectAllProps =
                            option.label === "Select All"
                              ? { checked: allSelectedDataSource }
                              : {};
                          return (
                            <li {...props}>
                              <Checkbox
                                checked={selectedOptions.some(
                                  (opt) => opt.label === option.label
                                )}
                                {...selectAllProps}
                                style={{ marginRight: 8 }}
                              />
                              {option.label}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            label="Data Source List"
                            error={!!errors.sourceId}
                            helperText={errors.sourceId?.message}
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4} display={"flex"} justifyContent={"end"}>
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={handleSubmit(onFormSubmit)}
                  disabled={!watch("sourceId").length}
                  sx={{
                    maxHeight: "2.5rem",
                    mr: "10px",
                  }}
                >
                  Generate Report
                </Button>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{
                    border:
                      columnDef.length > 0
                        ? "1px solid #2065D1"
                        : "1px solid #919eabcc",
                    //   background: "rgba(32, 101, 209, 0.08);",

                    textDecoration: "none",
                    borderRadius: "6px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    color: columnDef.length > 0 ? "#2065D1" : "#919eabcc",
                    fontSize: "0.875rem",
                  }}
                  disabled={true}
                >
                  <Typography fontWeight={700}>Number</Typography>
                  <Switch
                    checked={isPercentage}
                    disabled={!(columnDef.length > 0)}
                    onChange={(e) => {
                      // handlePersantageValues(!isPercentage)
                      // console.log(e.target.value);
                      setIsPercentage(!isPercentage);
                    }}
                  />
                  <Typography fontWeight={700}>Percentage</Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      {isLoading && <CircularProgressLoading />}

      {!isLoading && (
        <Box
          sx={{
            pt: 3,
          }}
        >
          <AgGridComponent
            columnDefs={columnDef}
            rowData={gridData}
            pagination={true}
            floatingFilter={false}
            paginationPageSize={10}
            onRowSelectionChangedHandler={(arg) => console.log(arg)}
            rowMode
          />
        </Box>
      )}
    </div>
  );
};

export default SourceDuplicateReport;
