import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {
  mdiViewDashboardOutline,
  mdiDatabaseOutline,
  mdiTextBoxMultipleOutline,
  mdiDatabaseImportOutline,
  mdiTableColumnWidth,
  mdiExportVariant,
} from "@mdi/js";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography, createTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Icon from "@mdi/react";

const options = [
  "Show some love to MUI",
  "Show all notification content",
  "Hide sensitive notification content",
  "Hide all notification content",
];
const routes = [
  {
    title: "Dashboard",
    content: "Business Insights at a Glance",
    icon: mdiViewDashboardOutline,
    route: "/dashboard",
  },
  {
    title: "Data Sources",
    content: "Add and Configure Data Sources",
    icon: mdiDatabaseOutline,
    route: "/manage-data-source",
  },
  {
    title: "File Sources",
    content: "Efficient File Handling",
    icon: mdiTextBoxMultipleOutline,
    route: "/available-files",
  },
  {
    title: "Map and Import",
    content: "Data Mapping and Import Wizard",
    icon: mdiDatabaseImportOutline,
    route: "map-data-column-and-import",
  },
  {
    title: "Data Store",
    content: "View Data Source Reports",
    icon: mdiTableColumnWidth,
    route: "/data-store-list",
  },
  {
    title: "Export Data",
    content: "Streamline Data Export Process",
    icon: mdiExportVariant,
    route: "/channel-list",
  },
  {
    title: "Reports",
    content: "Insights from Processed Data",
    icon: mdiTableColumnWidth,
    route: "report/import",
  },
  {
    title: "Raise an issue",
    content: "Service Support and Feedback",
    icon: mdiTableColumnWidth,
    // route: "/raise-an-issue",
  },
];
const theme = createTheme();

const SimpleListMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [navigateRoute, setNavigateRoute] = React.useState("");
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, route) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    setNavigateRoute(route);
  };

  React.useEffect(() => {
    if (navigateRoute) {
      navigate(navigateRoute);
    }
  }, [anchorEl]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <List
        component="nav"
        disablePadding
        aria-label="Device settings"
        // sx={{ bgcolor: "background.paper" }}
      >
        <ListItem
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText>
            <Button
              startIcon={
                <img
                  src="images/datalab-logo.svg"
                  width={30}
                  height={30}
                  className="headerIcon"
                  alt="send"
                />
              }
              endIcon={<KeyboardArrowDownIcon />}
              style={{
                textTransform: "capitalize",
                color: "#D4D4D4",
                fontSize: "1rem",
                fontWeight: 600,
              }}
            >
              Data labs
            </Button>
          </ListItemText>
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {routes.map((option, index) => (
          <MenuItem
            key={option.title}
            // disabled={index === 0}
            selected={index === selectedIndex}
            // component={Link}
            // to={option?.route}
            onClick={(event) =>
              handleMenuItemClick(event, index, option?.route)
            }
            sx={{
              width: "20rem",
            }}
          >
            <Grid container>
              <Grid xs={2}>
                <Icon path={option.icon} size={1} color="#06207C" />
              </Grid>
              <Grid
                xs={8}
                sx={{
                  sm: 10,
                  p: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <Typography fontWeight={600}>{option?.title}</Typography>
                <Typography>{option?.content}</Typography>
              </Grid>
            </Grid>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SimpleListMenu;
