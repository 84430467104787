import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import AgGridComponent from "../../../../components/common/AgGridComponent";
import CircularProgressLoading from "../../../../components/loaders/CircularProgressLoading";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useGetApiCallQuery } from "../../../../services/apiQueryServices";
import { updateDataHeaderTableData } from "../../../../store/DataSourceModule/DataheadersSlice";
const DataHeaders = () => {
  const [columnDef, setColumnDef] = useState([]);
  const dispatch = useDispatch();
  const TableData = useSelector((state) => state.dataHeader?.tableData);

  const { data, refetch, isError, isFetching, isLoading, isSuccess } =
    useGetApiCallQuery({
      endPoint: "dl/import/data-source/headers",
      method: "POST",
      data: {
        query: {},
        options: {
          page: 1,
          limit: 10,
          pagination: false,
          options: {},
        },
      },
      headers: {},
    });

  useEffect(() => {
    refetch();
    dispatchData();
  }, [data, isSuccess]);

  const dispatchData = () => {
    if (isSuccess) {
      const tableData = {
        data: [...data.data.data].reverse(),
        headers: [...data.data.headers],
      };
      dispatch(updateDataHeaderTableData(tableData));
    }
  };

  const errorCallback = (error) => {
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response.data.status.value || "An error occurred",
      })
    );
  };

  return (
    <Box sx={{ padding: "20px" }}>
      {isLoading ? (
        <CircularProgressLoading />
      ) : (
        <>
          <AgGridComponent
            rowData={TableData?.data || []}
            columnDefs={TableData.headers || []}
            onRowSelectionChangedHandler={() => {}}
            pagination={true}
            paginationPageSize={10}
            rowMode
          />
        </>
      )}
    </Box>
  );
};

export default DataHeaders;
