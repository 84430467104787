import React from "react";
import { Box, Button, Card, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const PageHeader = (props) => {
  const actionData = useLocation();
  const navigate = useNavigate();

  const handleButtonClick = (button) => {
    navigate(button?.route);
  };

  return (
    <Card
      sx={{
        height: "70px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6} md={4} ml={4}>
          <Box
            sx={{
              fontSize: "1.5rem",
              fontWeight: "700",
              marginTop: "5px",
              marginLeft: "30px",
            }}
          >
            {props?.title}
          </Box>
          <Box sx={{ marginLeft: "30px" }}>{props?.description}</Box>
        </Grid>
        <Grid item xs={6} md={7}>
          <Box display={"flex"} justifyContent={"end"} mt={2} gap={2}>
            {props?.navButtons?.map((button) => ( 
              <Button
                key={button.name}
                disabled={button.name === 'Error' || button.name === 'Analytics'}
                variant={
                  actionData.pathname === button?.route ||
                  actionData.pathname.includes(button?.route)
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleButtonClick(button)}
              >
                {button.name}
              </Button>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PageHeader;
