import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import GlobalInput from "@components/common/GlobalInput";
import * as Yup from "yup";
import { axiosInstance } from "@services/axiosInstance";
import { baseURL } from "@configs/envConfig";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Drawer,
  TextField,
  Divider,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { snackbarMessageUpdate } from "@store/config/configSlice";
import { useApiCallMutation } from "../../services/apiQueryServices";
import { addTableRow,updateTableRow } from "../../store/ExportDataModule/DestinationOwnerSlice";

const 
AddEditNewDataSource = (props) => {
  const { setOpen, isOpen, defaultValues, name, loadData, isEditMode } = props;
  const [defaultValuesSchema, setDefaultValuesSchema] = React.useState({});
  const dispatch = useDispatch();

  const [apiCall, res] = useApiCallMutation();
  const [apiCall1, res1] = useApiCallMutation();

  useEffect(() => {
    setDefaultValuesSchema(defaultValues);
    if (isEditMode) {
      SetValue();
    }
  }, [defaultValuesSchema]);

  useEffect(() => {
    
    if (res.isSuccess && res.data !== null) {
      successCallback(res.data);
      dispatch(addTableRow(res.data.data));
    }
    if (res1.isSuccess && res.data !== null) {
      successCallback(res1.data);
      dispatch(updateTableRow(res1.data.data));
    }
  }, [res, res1]);

 

  const initialValues = {
    name: "",
    apiUrl: "",
    apiHeaders: "",
    dataFields: "",
    description: "",
    remark: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This Field is required"),
    // apiUrl: Yup.string().required("This Field is required"),
    apiHeaders: Yup.string().required("This Field is required"),
    dataFields: Yup.string().required("This Field is required"),
    // description: Yup.string().required("This Field is required"),
    // remark: Yup.string().required("This Field is required"),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    triggerValidation,
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const headers = {
    "Content-Type": "application/json", // Example header
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("user"))?.token.access_token
    }`, // Example header
  };

  const SetValue = () => {
    setValue("name", defaultValuesSchema.name, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("apiUrl", defaultValuesSchema.apiUrl, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("apiHeaders", defaultValuesSchema.apiHeaders, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("dataFields", defaultValuesSchema.dataFields, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("description", defaultValuesSchema.description, {
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue("remark", defaultValuesSchema.remark, {
      shouldTouch: true,
      shouldDirty: true,
    });
  };
  
  const successCallback = (response) => {
    const status = response?.status;
    setOpen(false);
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "success",
        message: status.value,
      })
    );
  };
  const errorCallback = (error) => {
    dispatch(
      snackbarMessageUpdate({
        open: true,
        color: "error",
        message: error.response.data.status.value,
      })
    );
  };
 //***********FORM SUBMISSION ********//
  const onSubmit = (data) => {
    if (!isEditMode) {
      apiCall({
        endPoint: "dl/export/source-owner/create",
        method: "POST",
        data: { data },
        headers:{headers}
      });
      
    } else {
      apiCall1({
        endPoint: `dl/export/source-owner/update/${defaultValuesSchema?.id}`,
        method: "PUT",
        data: { data },
        headers:{headers}
      });
     
    }
    
  };

  return (
    <>
      <div>
        <Drawer anchor={"right"} open={isOpen} onClose={() => setOpen(false)}>
          <Box
            sx={{
              width: "50vw",
              pt: "1rem",
              pr: "1rem",
              pl: "1rem",
            }}
          >
            <Box>
              <Typography component="h5" variant="h5" fontWeight={600}>
                {isEditMode
                  ? "Update Data Source Destination Owner "
                  : "Add Data Source Destination Owner"}
              </Typography>
            </Box>
            <Divider
              sx={{
                mb: "20px",
              }}
            />
            <Box component={"form"} onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  {/* <TextField
                    size="small"
                    required
                    fullWidth
                    {...register("name")}
                    id="outlined-required"
                    label="Owner Name"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    // disabled={isEditMode}
                  /> */}
                  <GlobalInput
                    required
                    fullWidth
                    control={control}
                    size="small"
                    name="name"
                    label="Owner Name"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    // disabled={isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <TextField
                    size="small"
                    fullWidth
                    {...register("apiUrl")}
                    id="outlined-required"
                    label="API URL"
                    error={!!errors.apiUrl}
                    helperText={errors.apiUrl?.message}
                  /> */}
                  <GlobalInput
                    fullWidth
                    control={control}
                    size="small"
                    name="apiUrl"
                    label="API URL"
                    error={!!errors.apiUrl}
                    helperText={errors.apiUrl?.message}
                    // disabled={isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <TextField
                    size="small"
                    required
                    fullWidth
                    {...register("apiHeaders")}
                    id="outlined-required"
                    label="API Headers"
                    error={!!errors.apiHeaders}
                    helperText={errors.apiHeaders?.message}
                  /> */}
                  <GlobalInput
                    required
                    fullWidth
                    control={control}
                    size="small"
                    name="apiHeaders"
                    label="API Headers"
                    error={!!errors.apiHeaders}
                    helperText={errors.apiHeaders?.message}
                    // disabled={isEditMode}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <TextField
                    size="small"
                    required
                    fullWidth
                    {...register("dataFields")}
                    id="outlined-required"
                    label="Data Field"
                    error={!!errors.dataFields}
                    helperText={errors.dataFields?.message}
                  /> */}
                  <GlobalInput
                    required
                    fullWidth
                    control={control}
                    size="small"
                    name="dataFields"
                    label="Data Field"
                    error={!!errors.dataFields}
                    helperText={errors.dataFields?.message}
                    // disabled={isEditMode}
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* <TextField
                    size="small"
                    fullWidth
                    multiline
                    rows={4}
                    {...register("description")}
                    id="outlined-required"
                    label="Description"
                    error={!!errors.description}
                    helperText={errors.description?.message}
                  /> */}
                  <GlobalInput
                    fullWidth
                    multiline
                    rows={4}
                    control={control}
                    size="small"
                    name="description"
                    label="Description"
                    error={!!errors.description}
                    helperText={errors.description?.message}
                    // disabled={isEditMode}
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* <TextField
                    size="small"
                    fullWidth
                    multiline
                    rows={4}
                    {...register("remark")}
                    id="outlined-required"
                    label="Remark"
                    error={!!errors.remark}
                    helperText={errors.remark?.message}
                  /> */}
                  <GlobalInput
                    fullWidth
                    multiline
                    rows={4}
                    control={control}
                    size="small"
                    name="remark"
                    label="Remark"
                    error={!!errors.remark}
                    helperText={errors.remark?.message}
                    // disabled={isEditMode}
                  />
                </Grid>
              </Grid>
              
              <Box
                position={"fixed"}
                bottom={8}
                sx={{
                  backgroundColor: "#fefffe",
                  width: "100%",
                }}
              >
                <Grid container spacing={4} sx={{ width: "50vw" }}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      style={{
                        color: "#ff831f",
                        borderColor: "#ff831f",
                        opacity: (res.isLoading || res1.isLoading) ? 0.8 : 1,
                        pointerEvents: (res.isLoading || res1.isLoading) ? 'none' : 'auto',
                        cursor: (res.isLoading || res1.isLoading) ? 'not-allowed' : 'pointer',                
                      }}
                      onClick={handleSubmit(onSubmit)}
                    >
                      {(res.isLoading || res1.isLoading) ? "Wait..." : (isEditMode ? "Update" : "Add")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </div>
    </>
  );
};

export default AddEditNewDataSource;
