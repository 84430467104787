import React, { useEffect } from "react";
import PageHeader from "@components/ui/pageHeader/PageHeader";
import { Outlet } from "react-router";

const pageHeaderContent = {
  title: "Data Source",
  description: "Add and Configure Data Sources",
  navButtons: [
    { name: "Manage Data Sources", route: "/data-sources/manage-data-source" },
    { name: "Data Headers", route: "/data-sources/data-headers" },
    // { name: "Reports", route: "/data-sources/reports" },
  ],
};

const DataSources = () => {
  return (
    <>
      <PageHeader
        title={pageHeaderContent.title}
        description={pageHeaderContent?.description}
        navButtons={pageHeaderContent?.navButtons}
      />

      <div>
        <Outlet />
      </div>
    </>
  );
};

export default DataSources;
