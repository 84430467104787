// import { lazy } from "react";
// import { Navigate, useRoutes } from "react-router-dom";

// const MapDataColumnAndImport = lazy(() =>
//   import("./pages/MapAndImport/components/MapDataColumnAndImport")
// );
// const ImportOrResetMappedRawDataTables = lazy(() =>
//   import("./pages/MapAndImport/components/ImportOrResetMappedRawDataTables")
// );
// const HomePage = lazy(() => import("./pages/HomePage/Homepage"));

// const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));

// const DataSourcesReport = lazy(() =>
//   import("./pages/DataSourcesReport/DataSourcesReport")
// );
// const MapAndImport = lazy(() => import("./pages/MapAndImport/MapAndImport"));
// const Report = lazy(() => import("./pages/Report"));

// const DataStore = lazy(() => import("./pages/DataStore/DataStore"));
// const DataStorePage = lazy(() =>
//   import("./pages/DataStore/components/DataStorePage")
// );
// const SourceWiseCount = lazy(() =>
//   import("./pages/DataStore/components/SourceWiseCount")
// );

// const FileSource = lazy(() => import("./pages/FileSource/FileSource"));
// const AvailableFiles = lazy(() =>
//   import("./pages/FileSource/components/AvailableFiles")
// );
// const ImportedDataTables = lazy(() =>
//   import("./pages/FileSource/components/ImportedDataTables")
// );
// const ImportedFiles = lazy(() =>
//   import("./pages/FileSource/components/ImportedFiles")
// );

// const ExportData = lazy(() => import("./pages/ExportData/ExportData"));
// const ChannelList = lazy(() =>
//   import("./pages/ExportData/components/ChannelList")
// );
// const ChannelManagement = lazy(() =>
//   import("./pages/ExportData/components/ChannelManagement")
// );
// const ExportReports = lazy(() =>
//   import("./pages/ExportData/components/ExportReports")
// );

// const DataSources = lazy(() => import("./pages/DataSources/DataSources"));
// const DataHeaders = lazy(() =>
//   import("./pages/DataSources/components/DataHeaders")
// );
// const ManageDataSource = lazy(() =>
//   import("./pages/DataSources/components/ManageDataSource")
// );
// const Reports = lazy(() => import("./pages/DataSources/components/Reports"));

// const RaiseAnIssue = lazy(() => import("./pages//RaiseAnIssue"));

// const CentralOIDC = lazy(() => import("./pages/Authentication/CentralOIDC"));
// const CallbackHandlerPage = lazy(() =>
//   import("./pages/Authentication/CallbackHandlerPage")
// );
// const Logout = lazy(() => import("./pages/Authentication/Logout"));
// const ImportFilesTableData = lazy(() =>
//   import(
//     "./pages/FileSource/components/ImportedDataTables/component/ImportedFileTableData"
//   )
// );

import { Navigate, useRoutes } from "react-router-dom";
import { Min, Main } from "./layouts";
import HomePage from "./pages/HomePage/Homepage";
import Dashboard from "./pages/Dashboard/Dashboard";
import ExportData from "./pages/ExportData/ExportData";
import MapAndImport from "./pages/MapAndImport/MapAndImport";
import Report from "./pages/Report";
import RaiseAnIssue from "./pages/RaiseAnIssue";
import DataStore from "./pages/DataStore/DataStore";
import DataStorePage from "./pages/DataStore/components/DataStorePage";
import SourceWiseCount from "./pages/DataStore/components/SourceWiseCount";
import DataSources from "./pages/DataSources/DataSources";
import DataHeaders from "./pages/DataSources/components/DataHeaders";
import ManageDataSource from "./pages/DataSources/components/ManageDataSource";
import Reports from "./pages/DataSources/components/Reports";
import FileSource from "./pages/FileSource/FileSource";
import AvailableFiles from "./pages/FileSource/components/AvailableFiles";
import ImportedDataTables from "./pages/FileSource/components/ImportedDataTables";
import ImportFilesTableData from "./pages/FileSource/components/ImportedDataTables/component/ImportedFileTableData";
import ImportedFiles from "./pages/FileSource/components/ImportedFiles";
import ChannelList from "./pages/ExportData/components/ChannelList";
import ChannelManagement from "./pages/ExportData/components/ChannelManagement";
import ExportReports from "./pages/ExportData/components/ExportReports";
import CentralOIDC from "./pages/Authentication/CentralOIDC";
import CallbackHandlerPage from "./pages/Authentication/CallbackHandlerPage";
import Logout from "./pages/Authentication/Logout";
import ImportOrResetMappedRawDataTables from "./pages/MapAndImport/components/ImportOrResetMappedRawDataTables";
import MapDataColumnAndImport from "./pages/MapAndImport/components/MapDataColumnAndImport";
import CustomReport from "./pages/Report/components/custom-report";
import ImportReport from "./pages/Report/components/import";
import ExportReport from "./pages/Report/components/export";
import ErrorReport from "./pages/Report/components/error/Index";
import AnalyticsReport from "./pages/Report/components/analytics";

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Main />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: "home", element: <HomePage /> },
        { path: "dashboard", element: <Dashboard /> },
        // { path: "data-sources-report", element: <DataSourcesReport /> },
        { path: "export-data", element: <ExportData /> },
        { path: "map-and-import", element: <MapAndImport /> },
        { path: "raise-an-issue", element: <RaiseAnIssue /> },
        {
          element: <Report />,
          children: [
            { path: "/report/import", element: <ImportReport /> },
            { path: "/report/export", element: <ExportReport /> },
            { path: "/report/custom-reports", element: <CustomReport /> },
            // {path: "/report/power-bi-reports", element: <PowerBIReport/>},
            { path: "/report/error", element: <ErrorReport /> },
            { path: "/report/analytics", element: <AnalyticsReport /> },
          ],
        },

        {
          element: <DataStore />,
          children: [
            { path: "data-store/data-store-list", element: <DataStorePage /> },
            {
              path: "data-store/source-wise-count",
              element: <SourceWiseCount />,
            },
          ],
        },

        {
          element: <DataSources />,
          children: [
            { path: "data-sources/data-headers", element: <DataHeaders /> },
            {
              path: "data-sources/manage-data-source",
              element: <ManageDataSource />,
            },
            // { path: "data-sources/reports", element: <Reports /> },
          ],
        },

        {
          element: <FileSource />,
          children: [
            { path: "fileSource/available-files", element: <AvailableFiles /> },
            { path: "fileSource/imported-files", element: <ImportedFiles /> },
            {
              path: "fileSource/imported-data-tables",
              element: <ImportedDataTables />,
            },
            {
              path: "fileSource/imported-data-tables/:tablename",
              element: <ImportFilesTableData />,
            },
          ],
        },

        {
          element: <ExportData />,
          children: [
            { path: "exportData/channel-list", element: <ChannelList /> },
            {
              path: "exportData/channel-management",
              element: <ChannelManagement />,
            },
            // { path: "exportData/export-reports", element: <ExportReports /> },
          ],
        },
        {
          element: <MapAndImport />,
          children: [
            {
              path: "mapandimport/import-raw-data",
              element: <ImportOrResetMappedRawDataTables />,
            },
            {
              path: "mapandimport/map-data-column-and-import",
              element: <MapDataColumnAndImport />,
            },
          ],
        },
      ],
    },

    {
      path: "login",
      element: <CentralOIDC />,
    },

    {
      path: "callback",
      element: <CallbackHandlerPage />,
    },

    {
      path: "logout",
      element: <Logout />,
    },

    {
      element: <Min />,
      children: [
        { element: <Navigate to="/" />, index: true },
        // { path: '404', element: <Page404 /> },
        { path: "*", element: <Navigate to="/" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
